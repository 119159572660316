.container {
  text-align: center;
  margin: 50px 0;
}

.spin-click-button {
  background-color: #000;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border: none;
  padding: 14px 35px;
  border-radius: 15px;
}

.wheel-spin-box {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: visible;
}

.wheel-spin-arrow {
  position: relative;
  margin-top: -35px;
  text-align: center;
  z-index: 1;
}

.wheel-spin-arrow svg {
  max-width: 65px;
}
