.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Disable pointer events for cluster icons */
.gm-cluster {
  pointer-events: none !important;
  cursor: default !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-time-gutter > .rbc-timeslot-group {
  border: none !important;
  transform: translateY(-10px) !important;
  overflow: "visible" !important;
}

.rbc-time-content > .rbc-timeslot-group {
  overflow-y: none !important;
}

.rbc-time-content {
  /* border-top: 2px solid #ddd;*/
  border-top: none !important;
  overflow-y: visible !important;
}

.rbc-header {
    border-top: 2px solid #ddd !important;
}

.rbc-time-view {
    border: none !important;
}

.rbc-time-header .rbc-row {
  min-height: 0 !important;
  /* line-height: 60px; Adjust to vertically center content, if necessary */
}

.rbc-header {
  overflow: hidden !important;
  border-bottom: none !important;
  min-height: 50px !important;
}

.rbc-time-header .rbc-button-link {
  z-index: 100 !important;
  height: 60px !important;
  padding: 4px !important;
  position: relative !important;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

.rbc-events-container {
  border-top: 2px solid #ddd !important;
}

.rbc-event.event-type-availability  {
  width: 90% !important;
}

.rbc-event.event-type-visit,
.rbc-event.event-type-openVisit {
  left: 0% !important;
  width: 80% !important;
}

.firebase-emulator-warning { display: none; }

/* @media only screen and (max-width: 600px) {
  body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }
}  */

.no-gesture {
  touch-action: none;
}

.post-transition-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.post-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.post-transition-exit {
  opacity: 1;
}

.post-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

.fadeOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px; /* Adjust as needed */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none; /* Ensures the overlay doesn't interfere with clicks */
}

@media (min-width: 600px) {
  * {
      overscroll-behavior: none; /* Disables rubber band effect on non-mobile devices */
  }
}


/* Default behavior for smaller screens (below 600px) */
html, body {
  overscroll-behavior: auto; /* Allow normal scroll behavior for smaller screens */
}

/* Target screens larger than 600px */
@media (min-width: 600px) {
  * {
    overscroll-behavior: none; /* Disables rubber band effect on non-mobile devices */
  }
}


@media only screen and (max-width: 600px) { /* adjust as necessary for your mobile breakpoint */
  .hideScrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .hideScrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.digit {
  animation: fadeIn 0.5s ease-in-out;
  display: inline-block;
  min-width: 0.6em; /* Ensure digits don't shift */
  text-align: center;
}

.rollingDigit {
  transition: transform 0.25s ease-out;
  display: inline-block;
  height: 1em; /* Adjust based on your font size */
  overflow: hidden;
}

.rollingDigit span {
  display: inline-block;
  transition: transform 0.25s ease-out;
}

.pac-container {
  border-radius: 15px; /* Example: Add border radius */
  z-index: 2000; /* Ensure it's above other components */
}

.pac-item {
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  font-size: 0.95rem;
}

.pac-item-query {
  font-size: 1.05rem;
  padding-right: 6px;
}

.pac-icon {
  display: none;
}